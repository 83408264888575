import $ from 'jquery';
import 'slick-carousel';

export const menuToggle = () => {
  const menuButton = document.getElementById('menuButton');
  const navigation = document.getElementById('navigation');
  const backdrop = document.getElementById('menuBackdrop');

  const mediaQueryList = window.matchMedia('(min-width: 768px)');

  mediaQueryList.addEventListener('change', () => {
    if (mediaQueryList.matches) {
      navigation?.classList.remove('active');
      menuButton?.classList.remove('active');
    }
  });

  menuButton?.addEventListener('click', () => {
    const isMenuOpened = navigation?.classList.contains('active') && menuButton.classList.contains('active');
    if (isMenuOpened) {
      navigation?.classList.remove('active');
      menuButton?.classList.remove('active');
    } else {
      navigation?.classList.add('active');
      menuButton?.classList.add('active');
    }
  });

  backdrop?.addEventListener('click', () => {
    navigation?.classList.remove('active');
    menuButton?.classList.remove('active');
  });
};

export const slider = () => {
  $('.slider').slick({
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 750,
    arrows: false,
    responsive: [
      {
        breakpoint: 639,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 4,
          arrows: true
        }
      }
    ]
  });
};
